export const navigateTo = e => {
  e.preventDefault()
  const value = e.target.getAttribute("href")
  const targetWithoutHash = value.substring(1)

  history.replaceState(null, null, value)

  const offset = -100
  const element = document.getElementById(targetWithoutHash)
  const topWithOffset =
    element.getBoundingClientRect().top + window.pageYOffset + offset

  window.scrollTo({ top: topWithOffset, behavior: "smooth" })
}

export function arrayCheck(arr) {
  return Array.isArray(arr) && arr.length
}
