/** @jsx jsx */

import { any } from "prop-types"
import { jsx } from "theme-ui"

import Meta from "../src/components/Meta"
import SiteLayout from "../src/views/SiteLayout"

// Put persistent UI elements here
export const wrapPageElement = ({ element, props }) => {
  return <SiteLayout {...props}>{element}</SiteLayout>
}

wrapPageElement.propTypes = {
  element: any.isRequired,
  props: any.isRequired,
}

// Put providers here
export const wrapRootElement = ({ element }) => {
  return (
    <div>
      <Meta />
      {element}
    </div>
  )
}

wrapRootElement.propTypes = {
  element: any.isRequired,
}
