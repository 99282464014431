/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { jsx } from "theme-ui"

const Footer = () => {
  const {
    allDatoCmsFooter: { nodes }
  } = useStaticQuery(graphql`
    {
      allDatoCmsFooter {
        nodes {
          title
          postalAddress
          address
          zipAndArea
          corporatePropertyNumber
          email
          phoneNumber
        }
      }
    }
  `)

  const {
    title,
    postalAddress,
    address,
    zipAndArea,
    corporatePropertyNumber,
    email,
    phoneNumber
  } = nodes[0]

  return (
    <footer>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: ["50px 20px", "150px 20px"],
          maxWidth: "1200px",
          margin: "0 auto",
          fontSize: 2,
          fontWeight: "heading",
          lineHeight: "headding",
          fontFamily: "heading",
          color: "primary",
          textAlign: [null, "center"],

          "@media screen and (min-width: 540px)": {
            flexDirection: "row"
          }
        }}
      >
        <div
          sx={{
            marginBottom: 4,
            textAlign: "left",
            "@media screen and (min-width: 540px)": {
              paddingLeft: 5,
              width: "calc(100% / 3)"
            },

            "@media screen and (min-width: 990px)": {
              width: "20%"
            }
          }}
        >
          <h2
            sx={{
              fontSize: 2,
              fontWeight: "heading",
              margin: 0,
              padding: "5px 0",
              textAlign: "left"
            }}
          >
            {title}
          </h2>
          <span
            sx={{
              fontSize: 2,
              fontWeight: "heading",
              margin: 0,
              padding: "5px 0",
              textAlign: "left"
            }}
          >
            {address}
          </span>
        </div>

        <div
          sx={{
            marginBottom: 4,

            "@media screen and (min-width: 540px)": {
              paddingLeft: 5,
              width: "calc(100% / 3)"
            },
            "@media screen and (min-width: 990px)": {
              width: "20%"
            }
          }}
        >
          <address
            sx={{
              display: "flex",
              flexDirection: "column",
              fontStyle: "normal",
              textAlign: "left",

              span: {
                padding: "5px 0"
              }
            }}
          >
            <span>{postalAddress}</span>
            <span>{zipAndArea}</span>
          </address>
        </div>

        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",

            "@media screen and (min-width: 540px)": {
              paddingLeft: 5,
              width: "calc(100% / 3)"
            },

            "@media screen and (min-width: 990px)": {
              width: "20%"
            },

            span: {
              padding: "5px 0"
            },

            a: {
              fontWeight: "heading",
              lineHeight: "headding",
              fontFamily: "heading",
              color: "primary"
            }
          }}
        >
          <span>{corporatePropertyNumber}</span>
          <span>
            <a href={`mailto:${email}`}>{email}</a>
          </span>
          <span>{<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>}</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
