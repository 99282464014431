/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { array, bool } from "prop-types"
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react"
import Scrollspy from "react-scrollspy"
import { jsx } from "theme-ui"

import { arrayCheck, navigateTo } from "../../utils"

const getStyles = isSticky => ({
  menu: {
    display: "flex",
  },
  ul: {
    display: "flex",
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  li: {
    margin: "0 10px",

    "&.active": {
      "a::after": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
  a: {
    padding: "15px",
    fontSize: 3,
    color: "primary",
    fontWeight: 700,
    position: "relative",
    textDecoration: "none",

    "::after": {
      position: "absolute",
      content: '""',
      bottom: "5px",
      left: "10px",
      right: "10px",
      borderBottom: "2px solid #187cbc",
      opacity: 0,
      transition: "all .3s,opacity .3s,transform .3s",
      transform: "translateY(-10px)",
    },
    "&:hover": {
      "::after": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
  logoLink: {
    display: "inline-block",
    margin: "0 10px",
    padding: "0 15px",

    paddingTop: `${isSticky ? "20px" : "0px"}`,
    marginTop: `${isSticky ? "-20px" : "0px"}`,

    "&::after": { display: "none" },
  },
  logoStyle: {
    pointerEvents: "none",
    width: `${isSticky ? "60px" : "110px"}`,
    height: `${isSticky ? "60px" : "113px"}`,
    transition: "all ease-in-out .3s",
    marginTop: `${isSticky ? "-20px" : "0px"}`,
  },
})

const Desktop = ({ isSticky, leftMenuDesktop, rightMenuDesktop }) => {
  const [rightMenuItems, setRightMenuItems] = useState(null)
  const [leftMenuItems, setLeftMenuDesktop] = useState(null)

  useEffect(() => {
    if (arrayCheck(leftMenuDesktop) && arrayCheck(rightMenuDesktop)) {
      setRightMenuItems(rightMenuDesktop.flatMap(({ slug }) => slug))
      setLeftMenuDesktop(leftMenuDesktop.flatMap(({ slug }) => slug))
    }
  }, [leftMenuDesktop, rightMenuDesktop])

  const { menu, ul, li, a, logoStyle, logoLink } = getStyles(isSticky)

  const {
    allDatoCmsFrontpage: { nodes },
  } = useStaticQuery(graphql`
    {
      allDatoCmsFrontpage {
        nodes {
          logo {
            url
            alt
          }
        }
      }
    }
  `)

  const { logo } = nodes[0]

  return arrayCheck(leftMenuItems) && arrayCheck(rightMenuItems) ? (
    <div sx={menu}>
      <Scrollspy
        currentClassName="active"
        items={[...leftMenuItems, "start", ...rightMenuItems]}
        offset={-100}
        onUpdate={e => {
          if (e) {
            history.replaceState(null, null, `#${e.id}`)
          }
        }}
        sx={{ ...ul, li }}
      >
        {leftMenuDesktop.map(({ slug, title }) => (
          <li key={slug}>
            <a aria-label={slug} href={`#${slug}`} onClick={navigateTo} sx={a}>
              {title}
            </a>
          </li>
        ))}

        <li>
          <a href="#start" onClick={navigateTo} sx={logoLink}>
            <img
              alt={logo.url ? logo.url : "logo"}
              src={logo.url}
              sx={logoStyle}
            />
          </a>
        </li>

        {rightMenuDesktop.map(({ slug, title }) => (
          <li key={slug}>
            <a aria-label={slug} href={`#${slug}`} onClick={navigateTo} sx={a}>
              {title}
            </a>
          </li>
        ))}
      </Scrollspy>
    </div>
  ) : null
}

Desktop.propTypes = {
  isSticky: bool.isRequired,
  leftMenuDesktop: array.isRequired,
  rightMenuDesktop: array.isRequired,
}

export default Desktop
