/** @jsx jsx */
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { graphql, useStaticQuery } from "gatsby"
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react"
import { jsx } from "theme-ui"

import { navigateTo } from "../../utils"
import Desktop from "./Desktop"
import Hamburger from "./Hamburger"
import Mobile from "./Mobile"

const Navigation = () => {
  const [isSticky, setIsSticky] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [leftMenuDesktop, setLeftMenuDesktop] = useState(null)
  const [rightMenuDesktop, setRightMenuDesktop] = useState(null)

  useScrollPosition(({ currPos }) => {
    if (currPos.y !== 0) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  })

  const handleToggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleCloseMenu = () => {
    setIsOpen(false)
  }

  const handleNavigateTo = e => {
    navigateTo(e)
    setIsOpen(false)
  }

  const {
    allDatoCmsMenuItem: { menu },
  } = useStaticQuery(graphql`
    {
      allDatoCmsMenuItem(sort: { order: ASC, fields: position }) {
        menu: nodes {
          slug
          title
        }
      }
    }
  `)

  useEffect(() => {
    setLeftMenuDesktop(menu.slice(0, 2))
    setRightMenuDesktop(menu.slice(2, 4))
  }, [menu])

  return (
    <nav
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: [null, 7],
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        transition: "all ease-in-out .3s",
        zIndex: 500,
        fontFamily: "heading",

        "&::after": {
          position: "absolute",
          content: '""',
          top: 0,
          left: 0,
          right: 0,
          height: ["52px", "80px"],
          backgroundColor: "#fff",
          zIndex: "-1",
        },
      }}
    >
      <div sx={{ display: ["none", "initial"] }}>
        <Desktop
          isSticky={isSticky}
          leftMenuDesktop={leftMenuDesktop}
          rightMenuDesktop={rightMenuDesktop}
        />
      </div>
      <div sx={{ display: ["initial", "none"] }}>
        <Mobile
          handleCloseMenu={handleCloseMenu}
          handleNavigateTo={handleNavigateTo}
          isOpen={isOpen}
          isSticky={isSticky}
          menu={menu}
        />
      </div>

      <div
        sx={{
          display: ["initial", "none"],
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Hamburger active={isOpen} handleClick={handleToggleMenu} />
      </div>
    </nav>
  )
}

export default Navigation
