/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import Footer from "../components/Footer"
import Header from "../components/Header"

const SiteLayout = ({ children }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    }}
  >
    <Header />
    <main
      sx={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
          flex: "none",
        },
      }}
    >
      {children}
    </main>
    <Footer />
  </div>
)

SiteLayout.propTypes = {
  children: PropTypes.any.isRequired,
}

export default SiteLayout
