export default {
  colors: {
    black: "#000",
    white: "#fff",
    primary: "#187cbc",
    secondary: "#abd1a8",
    text: "#fbf4f4",
  },
  space: [0, 4, 8, 12, 15, 20, 25, 30, 50],
  radii: [5, 15, 25],
  breakpoints: ["768px"],
  fonts: {
    heading: "helvetica, arial, sans-serif",
    body: "helvetica, arial, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1,
  },
  fontSizes: [12, 14, 16, 18, 20, 30, 40, 48],
  text: {
    heading: {
      color: "inherit",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      m: 0,
    },
    body: {
      color: "inherit",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      m: 0,
    },
  },
  styles: {
    root: {
      backgroundColor: "white",
      scrollBehavior: "smooth",
      minWidth: "320px",
      boxSizing: "border-box",
    },
    p: {
      variant: "text.body",
      fontSize: 2,
    },
  },
}
