/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { array, bool, func } from "prop-types"
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useLayoutEffect, useState } from "react"
import Scrollspy from "react-scrollspy"
import { jsx } from "theme-ui"

import { arrayCheck } from "../../utils"

const getStyles = (isSticky, isOpen) => ({
  ul: {
    display: "block",
    textAlign: "center",
    listStyle: "none",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    width: "100vw",
    minWidth: "320px",
    backgroundColor: "#ffffff",
    margin: 0,
    padding: 0,
    paddingTop: 8,
    marginTop: 8,
    zIndex: -1,
    bottom: isOpen ? "0" : "",
    opacity: isOpen ? "1" : "0",
    pointerEvents: isOpen ? "auto" : "none",
    transition: "all ease-in-out 0.3s",
  },
  li: {
    margin: "12px 0",

    "&.active": {
      "a::after": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
  a: {
    display: "inline-block",
    padding: "15px",
    fontSize: 3,
    color: "primary",
    fontWeight: 700,
    position: "relative",
    textDecoration: "none",

    "::after": {
      position: "absolute",
      content: '""',
      bottom: "5px",
      left: "10px",
      right: "10px",
      borderBottom: "2px solid #187cbc",
      opacity: 0,
      transition: "all .3s,opacity .3s,transform .3s",
      transform: "translateY(-10px)",
    },

    "&:hover": {
      "::after": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
  logoLink: {
    display: "inline-block",
    margin: "0 10px",
  },
  logoStyle: {
    pointerEvents: "none",
    width: `${isSticky ? "40px" : "60px"}`,
    height: `${isSticky ? "40px" : "60px"}`,
    transition: "all ease-in-out .3s",
    marginTop: `${isSticky ? "5px" : "20px"}`,
    zIndex: 5,
  },
})

const Mobile = ({
  isSticky,
  handleCloseMenu,
  isOpen,
  menu,
  handleNavigateTo,
}) => {
  const [menuItems, setMenuItems] = useState(null)

  useEffect(() => {
    if (arrayCheck(menu)) {
      setMenuItems(menu.flatMap(({ slug }) => slug))
    }
  }, [menu])

  useEffect(() => {
    let resizeTimer
    window.addEventListener("resize", () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        if (window.innerWidth >= 768) {
          handleCloseMenu()
        }
      }, 250)
    })

    return () => {
      window.removeEventListener("resize", close)
    }
  }, [handleCloseMenu, isOpen])

  useLayoutEffect(() => {
    let overflowValue
    if (isOpen) {
      overflowValue = "hidden"
    } else {
      overflowValue = ""
    }

    document.body.style.overflow = overflowValue
  }, [isOpen])

  const { ul, li, a, logoStyle, logoLink } = getStyles(isSticky, isOpen)

  const {
    allDatoCmsFrontpage: { nodes },
  } = useStaticQuery(graphql`
    {
      allDatoCmsFrontpage {
        nodes {
          logo {
            url
            alt
          }
        }
      }
    }
  `)

  const { logo } = nodes[0]

  return arrayCheck(menuItems) ? (
    <>
      <a href="#start" onClick={handleNavigateTo} sx={logoLink}>
        <img alt={logo.url ? logo.url : "logo"} src={logo.url} sx={logoStyle} />
      </a>

      <Scrollspy
        currentClassName="active"
        items={menuItems}
        offset={-100}
        onUpdate={e => {
          if (e) {
            history.replaceState(null, null, `#${e.id}`)
          }
        }}
        sx={{ ...ul, li, a }}
      >
        {menu.map(({ title, slug }) => (
          <li key={slug}>
            <a aria-label={slug} href={`#${slug}`} onClick={handleNavigateTo}>
              {title}
            </a>
          </li>
        ))}
      </Scrollspy>
    </>
  ) : null
}

Mobile.propTypes = {
  handleCloseMenu: func.isRequired,
  handleNavigateTo: func.isRequired,
  isOpen: bool.isRequired,
  isSticky: bool.isRequired,
  menu: array.isRequired,
}

export default Mobile
