/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { jsx } from "theme-ui"

const Meta = () => {
  const {
    datoCmsSite: { favicon },
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      datoCmsSite {
        favicon: faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return <HelmetDatoCms favicon={favicon} title={title} />
}

export default Meta
