/** @jsx jsx */

import { bool, func } from "prop-types"
import { jsx } from "theme-ui"

const Hamburger = ({ active, handleClick }) => (
  <button
    aria-label="Open the menu"
    onClick={handleClick}
    sx={{
      width: "50px",
      height: "43px",
      padding: "10px",
      display: "inline-block",
      cursor: "pointer",
      transitionProperty: "opacity, filter",
      transitionDuration: "0.15s",
      transitionTimingFunction: "linear",
      font: "inherit",
      color: "inherit",
      textTransform: "none",
      backgroundColor: "transparent",
      border: 0,
      margin: 0,
      overflow: "visible",
      zIndex: 10,
    }}
    type="button"
  >
    <span
      sx={{
        width: "30px",
        display: "block",
        position: "absolute",
      }}
    >
      <span
        sx={{
          display: "block",
          top: "auto",
          marginTop: "-2px",
          width: "30px",
          height: "3px",
          backgroundColor: "#187cbc",
          borderRadius: "4px",
          position: "absolute",
          transitionProperty: "transform",
          transitionDuration: "0.075s",
          transitionTimingFunction: active
            ? "cubic-bezier(0.215, 0.61, 0.355, 1)"
            : "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
          transform: active ? "rotate(45deg)" : "",
          transitionDelay: active ? "0.12s" : "",

          "::before": {
            width: "30px",
            height: "3px",
            backgroundColor: "#187cbc",
            borderRadius: "4px",
            position: "absolute",
            transitionProperty: "transform",
            transitionDuration: "0.15s",
            transitionTimingFunction: "ease",
            transition: active
              ? "top 0.075s ease, opacity 0.075s 0.12s ease"
              : "top 0.075s 0.12s ease, opacity 0.075s ease",
            content: '""',
            display: "block",
            top: active ? "0" : "-10px",
            opacity: active ? "0" : "",
          },

          "::after": {
            width: "30px",
            height: "3px",
            backgroundColor: "#187cbc",
            borderRadius: "4px",
            position: "absolute",
            transitionProperty: "transform",
            transitionDuration: "0.15s",
            transitionTimingFunction: "ease",
            transition: active
              ? "bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)"
              : "bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)",
            content: '""',
            display: "block",
            bottom: active ? "0" : "-10px",
            transform: active ? "rotate(-90deg)" : "",
          },
        }}
      />
    </span>
  </button>
)

Hamburger.propTypes = {
  active: bool.isRequired,
  handleClick: func.isRequired,
}

export default Hamburger
